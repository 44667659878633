import React, { useState ,useEffect} from "react";

import { postApisignupimp ,countriesaction} from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate  } from "react-router-dom";

import "./login.css";
import { SettingFilled } from "@ant-design/icons";

const Signup = () => {

  //initialize navigate
  let navigate = useNavigate();
  //BACK BUTTON FUNCTION
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  //states for store field data
    const [fullname, setfullname] = useState();
    const [email, setemail] = useState();
    const [confirmemail, setconfirmemail] = useState();
    const [mobile, setmobile] = useState();
    const [gender, setgender] = useState();
    const [Confirmpass, setConfirmpass] = useState();
    const [Password, setpaswoord] = useState();
    const [country, setcountry] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const [fullnamevalidator, setfullnamevalidator] = useState(false);
    const [mobilevalidater, setmobilevalidater] = useState(false);
    const [emailvalidator, setemailvalidator] = useState(false);
    const [passwordvalidator, setpasswordvalidator] = useState(false);
    const [filled, SetFilled] = useState(false);

   
   

 
///initiaialize dispatch
  const dispatch = useDispatch();

  const loginResponce = useSelector((state) => state.reducer.countries);
  const responseData = useSelector((state) => state.reducer.signupresponse);
    
   
 
 
   ///COUNTRIES LIST IN LOGINRESPONSE
   
    const final = loginResponce;
    
    // useEffect(() => {
     
    // }, []);
    useEffect(() => {
   
    }, []);
 
    useEffect(() => {
 
      dispatch(countriesaction());
    }, [countriesaction]);
   
 
 
  //signup button code
  const clickHandler = (e) => {
    e.preventDefault();
   
    const finalData = {

      userName:fullname,
      
      gender:gender,
      contact:mobile,
      email:email,
      password:Password,
      country:country,
      
        // Confirmpass:Confirmpass,
     
     
    };
    
    console.log("fiiiiiiiiiiii",finalData)

    //MOBILE NO REGEX
    const num=/^[+]?[0-9]+([0-9 ])*$/
    //FULLNAME REGEX
    const name = /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/
    //PASSWEORD REGEX
    const pass =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,24}$/
   ///EMAIL REGEX
    const reg= /^(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i

    console.log("result",reg.test(email))
    //signup function
    if(name.test(fullname)){
      setfullnamevalidator(false)
      if (num.test(mobile)){
        setmobilevalidater(false)
        if(reg.test(email) ){
          setemailvalidator(false)
          if(Password.length>7){
           
            if(email==confirmemail){
              setpasswordvalidator(false)
              if (fullname && gender && mobile && email&& Password){
                
                if(Password==Confirmpass){
  
                 
                  setLoading(true)
                  dispatch(postApisignupimp(finalData)).then((result) => {
                    console.log("this is result",result )
                    if (result === true) {
  
                         setLoading(false) 
                      alert("Congratulations! You are now an Impactor.")
                      navigate("/otp " );
                     
                      
                     
                    }else{
                   
                      alert("User Already Exists!")
                      navigate("/Forgot_password")
                    }
                  }).catch((error) => {
                    
                    alert("User Already Exists!!")
                    navigate("/Forgot_password")
                  })
               
              }else{
                alert(" Password or Confirm Password fields should match")
              }
            }else{
              
              SetFilled(true)
            }
            }else{
              alert("Email Address or Confirm Email Address fields should match")
            }
            
          }else{
            setpasswordvalidator(true)
           
          }
        }else{
          setemailvalidator(true)
         
        }
      }
      else
      {
        setmobilevalidater(true)
      
      }      
    }else{ 
      setfullnamevalidator(true)
    
    } 
  };

  
   
  return (
    <div className='bg2'>
    <div className='wrap2'>
     
    <form onSubmit={clickHandler}>

   
    <div  className="loginForm">
      <h3>Sign up</h3>
      </div>
    <div className="form-group">
        <label className="headingleft txt">Full Name </label>
        <input onChange={(e) => {
                        setfullname(e.target.value.trim());
                       
                      }} type="name" className="form-control" placeholder="Enter your full name (Only Alphabets and Space) " />
    </div>
    <label className="label_loading" >
                  {fullnamevalidator==true?"Full name can only contain alphabets and spaces":" "} 
                </label>
    <div className="form-group">
        <label className="headingleft txt">Email Address </label>
        <input onChange={(e) => {
                        setemail(e.target.value.trim());
                      }}  className="form-control" placeholder="Enter your email address" />
    </div>
    <div className="form-group">
        <label className="headingleft txt">Confirm Email Address </label>
        <input onChange={(e) => {
                        setconfirmemail(e.target.value.trim());
                        
                      }}  className="form-control" placeholder="Confirm your email address" />
    </div>
    <label className="label_loading" >
                  {emailvalidator==true?"Please enter your valid email address  ":" "} 
                </label>
    <div className="form-group">
                    <label htmlFor="tipoId"className="headingleft txt">
                    Select Country  
                    </label>
                    <select className="form-control"
                    onClick={(event) => {
                        // here set target value to state which is 0, 1, 2, 3
                        setcountry(event.target.value);
                    }}>
                        <option value>Select Country  </option>
                        {final?.map((x, index) => {
                      return (
                        <option key={index}>{x.name }</option>
                      );
                    })}
                       
                    </select>
                </div>
             
    <div className="form-group">
        <label className="headingleft txt">Mobile Number</label>
        <input onChange={(e) => {
                        setmobile(e.target.value.trim());
                      }} type="txt" className="form-control" placeholder="Enter your mobile number" />
    </div>
    <label className="label_loading" >
                  {mobilevalidater==true?"Mobile Number should only consist of numbers, + (begining only) sign or spaces.":""} 
                </label>
    <div className="form-group">

    <label className=" txt">Select Your Gender </label> 
    <br></br>
    <div>
    <div className='form-check form-check-inline'>
   <input className="form-check-input" onChange={(e) => {
                        setgender(e.target.value);
                      }} type="radio" id="html" name="fav_language" defaultValue="Male" />
   <label  className=" form-check-label">Male</label><br />
   </div>
   <div className='form-check form-check-inline'>
  <input className="form-check-input" onChange={(e) => {
                        setgender(e.target.value);
                      }} type="radio" id="css" name="fav_language" defaultValue="Female" />
   <label className="  form-check-label">Female</label><br />
   </div>
   <div className='form-check form-check-inline'>
   <input className="form-check-input" onChange={(e) => {
                        setgender(e.target.value);
                      }} type="radio" id="javascript" name="fav_language" defaultValue="Prefer not to answer" />
   <label  className=" form-check-label">Prefer not to answer</label>
   </div>

    </div>


    </div>
   

    <div className="form-group">
        <label className="headingleft txt">Password </label>
        <input onChange={(e) => {
                        setpaswoord(e.target.value);
                      }} type="password" className="form-control" placeholder="Enter your password (Minimum Eight Characters)" />
    </div>
    <label className="label_loading" >
                  {passwordvalidator==true?"Password should have minimum 8 characters":" "} 
                </label>
    <div className="form-group">
        <label className="headingleft txt">Confirm Password</label>
        <input onChange={(e) => {
                        setConfirmpass(e.target.value);
                      }} type="password" className="form-control" placeholder="Enter your password (Minimum Eight Characters)" />
    </div>
    {/* <label className="label_loading" >
                  {passwordvalidator==true?"Password must contain one lowercase letter, one uppercase letter, one number, one special character from (@$!%*?&) and number of characters between 8 and 24":" "} 
                </label> */}
   
    {loading?"":<button type="submit"  className=" btn btn-dark btn-block">Sign up</button>
}
   
    <a href="/Forgot_password" className="forgotbtn">In case of PIN expiry, please click here.</a>
<br>
</br>
    <button onClick={navigateHome} className="btn btn-block">Back To Home Page</button>


    <label className="label_loading" >
                  {loading?"Processing....":" "} 
                </label>
     
</form>
</div>
</div>
  )
}


export default Signup