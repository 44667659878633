import {GET_DETAILS,POST_DETAILS,POST_CART,POST_LOGIN,POST_TID,POST_SIGNUP,POST_VERIFY,IMPACT_POINT,CREATE_SESION,TRANSECTION_DETAILS,POST_FORGET_SEND_API,SEND_FORGOT,COUNTRY_LIST,RESET_PASSWORD,FORGET_PASSWORD,LOGOUT_USER} from '../reducer/type'
import {GetApiDetails,postApiDetails,addToCart,postApilogin,posttid,postApisignup,postverifyotp,calculateimpactpoint,createsesion,postsendpassotp,postApiSETPASSWORD,Gettransection,changepassword,Getcountrieslist,logout} from '../../api/axiosRequest'


const Action =( ) =>{
   return function (dispatch){
    return GetApiDetails().then((res)=>{
       

        dispatch({
            type:GET_DETAILS,
            payload:res.data,
        })
    });
   
   };
    };
const countriesaction =( ) =>{
    return function (dispatch){
        return Getcountrieslist().then((res)=>{
        console.log("response",res.data.data)
    
            dispatch({
                type:COUNTRY_LIST,
                payload:res.data.data,
            })
        });
    
    };
        };
const transectiondetail =(p1 ) =>{
    console.log("amount is",p1)
    return function (dispatch){
        return Gettransection(p1).then((res)=>{
        
    
            dispatch({
                type:TRANSECTION_DETAILS,
                payload:res.data,
            })
        });
    
    };
        };

const PostapiAction =(request) =>{
   
   return function (dispatch){
    return postApiDetails(request).then((res)=>{
        console.log("required action",res)
        console.log("required action",res.data.data)
        
        
      res.status===200?
      
        dispatch({
            
            type:POST_DETAILS,
            
            payload:res.data.data,
            
        }):
        dispatch({

            type:POST_DETAILS,
            
            payload:{},
        })

    });
   
   };
    };
const postInCart =(request,p1) =>{
   
   return function (dispatch){
    return addToCart(request,p1).then((res)=>{
            console.log("i am in then action of add to cart ",res.data.data,res)
            if( res.status===200){
              dispatch({
                  
                  type:POST_CART,
                  
                  payload:res.data,
                  
              })
            //   resolve(true);
            }else{
              dispatch({
      
                  type:POST_CART,
                  
                  payload:"",
              })

            }
            // reject(false)
          });
    // }) 
   };
    };
const Postapilogin =(request) =>{
       
       return function (dispatch){
        return new Promise((resolve, reject)=>{
            postApilogin(request).then((res)=>{
             
                
                if(res.status===200){
                    dispatch({
                        type:POST_LOGIN,
                        payload:res.data.data,
                        
                    })
                    resolve(true)
                }else{
                    dispatch({
        
                        type:POST_LOGIN,
                        
                        payload:{},
                    })
                    reject(false)
                }
            }).catch((error)=>{
                
                reject(error)
            })
        })
       
       };
        };

//VERIFY OTP
const postverify =(request) =>{
    
    return function (dispatch){
    return postverifyotp(request).then((res)=>{
        console.log("post verify-->> ",res.data)
        
        
        // res.status===200?
        
        dispatch({
            
            type:POST_VERIFY,
            
            payload:res.data,
            
        })
        // :
        // dispatch({

        //     type:POST_VERIFY,
            
        //     payload:{},
        // })

    }).catch((res)=>{
        console.log("i am in cath of res ",res)
        dispatch({
            
            type:POST_VERIFY,
            
            payload:res.response.data,
            
        })
    });
    
    };
    };

//SET PASSWORD
const postsetpassword =(request) =>{
    
    return function (dispatch){
    return postApiSETPASSWORD(request).then((res)=>{
        // console.log(res)
        
        
        // res.status===200?
        
        dispatch({
            
            type:SEND_FORGOT,
            
            payload:res.data,
            
        })

    }).catch((res)=>{
        dispatch({

            type:SEND_FORGOT,
            
            payload:res.response.data,
        })
    });
    
    };
    };

//SEND OTP
const postforgetsendotp =(request) =>{

    return function (dispatch){
        return  postsendpassotp(request).then((res)=>{
                console.log("then-data forget --->> ",res)
                
                
                // if(res.status===200){
                    dispatch({
                        type:FORGET_PASSWORD,
                        payload:res.data,
                        
                    })
                // }else{
                //     dispatch({
        
                //         type:FORGET_PASSWORD,
                        
                //         payload:{},
                //     })
                //     reject(false)
                // }
            }).catch((res)=>{
                console.log("then-data forget vath--->> ",res)
                dispatch({
                    type:FORGET_PASSWORD,
                    payload:res.response.data,
                    
                })
            })
        
    
    };
    };






const postApisignupimp =(request) =>{
       
        return function (dispatch){
         return new Promise((resolve, reject)=>{
            postApisignup(request).then((res)=>{
              
                 console.log("res",res)
                 if(res.status===201){
                     dispatch({
                         type:POST_SIGNUP,
                         payload:res.data.data,
                         
                     })
                     resolve(true)
                 }else{
                     dispatch({
         
                         type:POST_SIGNUP,
                         
                         payload:{},
                     })
                     reject(false)
                 }
             }).catch((error)=>{
                 
                 reject(error)
             })
         })
        
        };
         };

// const postApisignupimp =(request) =>{

//     return function (dispatch){
//     return postApisignup(request).then((res)=>{
//         // console.log(res)
        
        
//         // res.status===200?
        
//         dispatch({
            
//             type:POST_SIGNUP,
//             payload:res.data.data,
            
//         })

//     }).catch((res)=>{
//         dispatch({

//             type:POST_SIGNUP,
                        
//                         payload:{},
//         })
//     });
    
//     };
//     };
// const postApisignupimp =(request) =>{
   
//     return function (dispatch){
//         return new Promise((resolve, reject)=>{
//             postApisignup(request).then((res)=>{
                
                
//                 if(res.status===201){
//                     dispatch({
//                         type:POST_SIGNUP,
//                         payload:res.data.data,
                        
//                     })
//                     resolve(true)
//                 }else{
//                     dispatch({
        
//                         type:POST_SIGNUP,
                        
//                         payload:{},
//                     })
//                     reject(false)
//                 }
//             }).catch((error)=>{
                
//                 reject(error)
//             })
//         })
 
//     };
//     };

const PostTid =(p1,p2) =>{
    console.log("data",p1,p2)
   
    return function (dispatch){
        return posttid(p1,p2).then((res)=>{
           
    
            dispatch({
                type:POST_TID,
                payload:res.data.data,
            })
        });
    
    };
        };
const calculatePoint =(amnt,param1) =>{
  

    return function (dispatch){
        return calculateimpactpoint(amnt,param1).then((res)=>{
           
    
            dispatch({
                type:IMPACT_POINT,
                payload:res.data.data,
            })
        });
    
    };
        };
const saveAuthToken =( auth) =>{
  
    return function (dispatch){
        dispatch({
            type:"AUTH_TOKEN",
            payload:auth
          })
    
};
};

const createSesionipg  = (request,p1) =>{
   
   return function (dispatch){
    return new Promise((resolve, reject)=>{
        createsesion(request,p1).then((res)=>{
            if(res.status == 200){
                dispatch({
                  
                    type:CREATE_SESION,
                    
                    payload:res.data.data,
                    
                })
                resolve ({
                    success :true,
                    "data": res.data.data
                })
            }else{
                dispatch({
                    type:POST_DETAILS,
                    payload:{},
                })
                reject( {
                    success: false
                })
            }
          });
    }) 
   
   };
    };


    //////logout///

const clicklogout  = (p1) =>{

    return function (dispatch){
        return new Promise((resolve, reject)=>{
            logout(p1).then((res)=>{
                if(res.status == 200){
                    dispatch({
                    
                        type:LOGOUT_USER,
                        
                        
                        
                    })
                    resolve ({
                        success :true,
                        
                    })
                }else{
                    dispatch({
                        type:LOGOUT_USER,
                        payload:{},
                    })
                    reject( {
                        success: false
                    })
                }
            });
        }) 
    
    };
        };
     

 
 ///////  



const userresetPassword  = (request,p1) =>{

    return function (dispatch){
        return changepassword(request,p1).then((res)=>{
                // if(res.status == 200){
                    dispatch({
                    
                        type:RESET_PASSWORD,
                        
                        payload:res.data,
                        
                    })
                    
                // }else{
                //     dispatch({
                //         type:RESET_PASSWORD,
                //         payload:{},
                //     })
                   
                // }
            }).catch((res)=>{
                dispatch({
                    
                    type:RESET_PASSWORD,
                    
                    payload:res.response.data,
                    
                })
            }

            );
     
    
    };
        };
export {clicklogout,Action, PostapiAction,postInCart,Postapilogin,PostTid,postApisignupimp,postverify,saveAuthToken,calculatePoint,createSesionipg,postforgetsendotp,postsetpassword,transectiondetail,userresetPassword,countriesaction};





// import {GET_DETAILS,POST_DETAILS,POST_CART,POST_LOGIN,POST_TID,POST_SIGNUP,POST_VERIFY,IMPACT_POINT,CREATE_SESION} from '../reducer/type'
// import {GetApiDetails,postApiDetails,addToCart,postApilogin,posttid,postApisignup,postverifyotp,calculateimpactpoint,createsesion,postsendpassotp} from '../../api/axiosRequest'


// const Action =( ) =>{
//    return function (dispatch){
//     return GetApiDetails().then((res)=>{
       

//         dispatch({
//             type:GET_DETAILS,
//             payload:res.data,
//         })
//     });
   
//    };
//     };

// const PostapiAction =(request) =>{
   
//    return function (dispatch){
//     return postApiDetails(request).then((res)=>{
        
        
        
//       res.status===200?
      
//         dispatch({
            
//             type:POST_DETAILS,
            
//             payload:res.data.data,
            
//         }):
//         dispatch({

//             type:POST_DETAILS,
            
//             payload:{},
//         })

//     });
   
//    };
//     };
// const postInCart =(request,p1) =>{
   
//    return function (dispatch){
//     return addToCart(request,p1).then((res)=>{
//            // console.log("i am in then action of add to cart ",res.data.data,res)
//             if( res.status===200){
//               dispatch({
                  
//                   type:POST_CART,
                  
//                   payload:res.data,
                  
//               })
//             //   resolve(true);
//             }else{
//               dispatch({
      
//                   type:POST_CART,
                  
//                   payload:"",
//               })

//             }
//             // reject(false)
//           });
//     // }) 
//    };
//     };
// const Postapilogin =(request) =>{
       
//        return function (dispatch){
//         return new Promise((resolve, reject)=>{
//             postApilogin(request).then((res)=>{
             
                
//                 if(res.status===200){
//                     dispatch({
//                         type:POST_LOGIN,
//                         payload:res.data.data,
                        
//                     })
//                     resolve(true)
//                 }else{
//                     dispatch({
        
//                         type:POST_LOGIN,
                        
//                         payload:{},
//                     })
//                     reject(false)
//                 }
//             }).catch((error)=>{
                
//                 reject(error)
//             })
//         })
       
//        };
//         };

// const postverify =(request) =>{

//     return function (dispatch){
//     return postverifyotp(request).then((res)=>{
//         console.log("post verify-->> ",res.data)
        
        
//         // res.status===200?
        
//         dispatch({
            
//             type:POST_VERIFY,
            
//             payload:res.data,
            
//         })
//         // :
//         // dispatch({

//         //     type:POST_VERIFY,
            
//         //     payload:{},
//         // })

//     }).catch((res)=>{
//         console.log("i am in cath of res ",res)
//         dispatch({
            
//             type:POST_VERIFY,
            
//             payload:res.response.data,
            
//         })
//     });
    
//     };
//     };
// // const postverify =(request) =>{
    
// //     return function (dispatch){
// //     return postverifyotp(request).then((res)=>{
        
        
        
// //         res.status===200?
        
// //         dispatch({
            
// //             type:POST_VERIFY,
            
// //             payload:res.data.data.authToken,
            
// //         }):
// //         dispatch({

// //             type:POST_VERIFY,
            
// //             payload:{},
// //         })

// //     });
    
// //     };
// //     };

// const postApisignupimp =(request) =>{
   
//     return function (dispatch){
//         return new Promise((resolve, reject)=>{
//             postApisignup(request).then((res)=>{
                
                
//                 if(res.status===201){
//                     dispatch({
//                         type:POST_SIGNUP,
//                         payload:res.data.data,
                        
//                     })
//                     resolve(true)
//                 }else{
//                     dispatch({
        
//                         type:POST_SIGNUP,
                        
//                         payload:{},
//                     })
//                     reject(false)
//                 }
//             }).catch((error)=>{
                
//                 reject(error)
//             })
//         })
 
//     };
//     };

// const PostTid =(p1,p2) =>{
//     //console.log("data",p1,p2)
   
//     return function (dispatch){
//         return posttid(p1,p2).then((res)=>{
           
    
//             dispatch({
//                 type:POST_TID,
//                 payload:res.data.data,
//             })
//         });
    
//     };
//         };
// const calculatePoint =(amnt,param1) =>{
  

//     return function (dispatch){
//         return calculateimpactpoint(amnt,param1).then((res)=>{
           
    
//             dispatch({
//                 type:IMPACT_POINT,
//                 payload:res.data.data,
//             })
//         });
    
//     };
//         };
// const saveAuthToken =( auth) =>{
  
//     return function (dispatch){
//         dispatch({
//             type:"AUTH_TOKEN",
//             payload:auth
//           })
    
// };
// };
// //SEND OTP
// const postforgetsendotp =(request) =>{

//     return function (dispatch){
//         return  postsendpassotp(request).then((res)=>{
//                 console.log("then-data forget --->> ",res)
                
                
//                 // if(res.status===200){
//                     dispatch({
//                         type:FORGET_PASSWORD,
//                         payload:res.data,
                        
//                     })
//                 // }else{
//                 //     dispatch({
        
//                 //         type:FORGET_PASSWORD,
                        
//                 //         payload:{},
//                 //     })
//                 //     reject(false)
//                 // }
//             }).catch((res)=>{
//                 console.log("then-data forget vath--->> ",res)
//                 dispatch({
//                     type:FORGET_PASSWORD,
//                     payload:res.response.data,
                    
//                 })
//             })
        
    
//     };
//     };
// const createSesionipg  = (request,p1) =>{
   
//    return function (dispatch){
//     return new Promise((resolve, reject)=>{
//         createsesion(request,p1).then((res)=>{
//             if(res.status == 200){
//                 dispatch({
                  
//                     type:CREATE_SESION,
                    
//                     payload:res.data.data,
                    
//                 })
//                 resolve ({
//                     success :true,
//                     "data": res.data.data
//                 })
//             }else{
//                 dispatch({
//                     type:POST_DETAILS,
//                     payload:{},
//                 })
//                 reject( {
//                     success: false
//                 })
//             }
//           });
//     }) 
   
//    };
//     };
//     export {Action, PostapiAction,postInCart,Postapilogin,PostTid,postApisignupimp,postverify,saveAuthToken,calculatePoint,createSesionipg,postforgetsendotp};