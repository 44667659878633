import React,{useEffect} from 'react';
import Action from '../redux/Actions/actions';
import { useDispatch,useSelector } from 'react-redux';
import logo from '../assets/logo.png';
import akm from '../assets/akm.png';
import akd from '../assets/akd.png';

import {
  MDBCard,
 
  MDBCardImage,
  
  MDBContainer 
} from 'mdb-react-ui-kit';
// import Forms from "forms"
import Header from './header';
import './home.css';

const Home = () => {
  
  
  return (
    <>
    
    <div className="bg">
    <img src={logo} alt="Paris" className="center"></img>
    <div >
    <h3 className='heading center'>Select NGO you want to donate</h3>
    </div>
  
      
      
    <MDBContainer fluid> 
    
    <div className='row main-div'>
    <div className='col-3'></div>
      
      <div className='col-3'>
      <MDBCard  href="/checkout/AKM" tag="a">
      
      <MDBCardImage
        src={akm}
        position="top"
        alt="..."
      />
      
        
      
    </MDBCard></div>
    <div className='col-3'><MDBCard  href="/checkout/AKD" tag="a">
      <MDBCardImage
        src={akd}
        position="top"
        alt="..."
      />
      
        
      
    </MDBCard></div>
   
    <div className='col-3'></div>
    
    
    </div>
    
    
    
    
    
    
    
    
    
    </MDBContainer>
     
    

    {/* <MDBCard style={{width: "18rem"}}>
      <MDBCardImage
        src="https://mdbootstrap.com/img/new/standard/city/041.jpg"
        position="top"
        alt="..."
      />
      <MDBCardBody>
        <MDBCardTitle>Card with stretched links</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the bulk of the
          card's content.
        </MDBCardText>
        <MDBCardText>
          <a href="#" className="stretched-link text-danger position-relative"
            >Stretched link will not work here, because position: relative is
            added to the link</a
          >
        </MDBCardText>
        <p className="card-text bg-light" style={{transform: "rotate(0)"}}>
          This <a href="#" className="text-warning stretched-link">stretched link</a> will only
          be spread over the p-tag, because a transform is applied to it.
        </p>
      </MDBCardBody>
    </MDBCard> */}






    
   
</div>













   
      
    {/* <Forms/> */}
    {/* <div class="container text-center">
  <div class="row">
    <div class="col">
      Column
    </div>
    <div class="col">
      Column
    </div>
    <div class="col">
      Column
    </div>
  </div>
</div> */}
     {/* {result} */}
    
    </>
  )
}

export default Home
