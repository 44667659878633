
import React, { useState } from "react";
import { useEffect } from "react";
import { Action, PostapiAction, PostTid, calculatePoint, createSesionipg, postInCart } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import "./confirm.css";
import tick from '../assets/tick.png'
import logo from '../assets/logo.png'

function    ImpactorConfirm() {



  const dispatch = useDispatch();
   //GET IMPECT POINTS
   const Points = useSelector((state) => state.reducer.impactpoint);
   const imp_point=Points.impactPoints

  return (
   
 <div>
  
  <section className="section-two">
    <div className="container-two">
      <div className="container-two-content content">
      
      
      <br></br>
      <br></br>
      <img className='logoimg' src={logo} alt="Italian Trulli" />
      <br></br>
      <br></br>
      <br></br>
      <img className='tickimg' src={tick} alt="Italian Trulli" />
      <br></br>
      <br></br>
      <br></br>
      

      

        <h2 >Your donation has been successful.</h2>
        <h2 className="signUpTextDiv">
          You will get `${imp_point}` impact credits from M!M
        </h2>

        <br></br>
      <br></br>
      <br></br>
      <br></br>
      
      </div>
      
    </div>
  </section>
 
</div>


  )
}

export default ImpactorConfirm
