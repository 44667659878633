import {  Navigate} from 'react-router-dom';

import { useSelector } from 'react-redux';



///ROUTE TO CHECK AUTHENTICATION

function PrivateRoute({ children }) {
   
    // let navigate = useNavigate();
    
    const loginResponce = useSelector((state) => state.reducer.loginresponse);
    
   
    // console.log("response",useSelector(x => x.loginresponse.authToken))
    if (!loginResponce.authToken || loginResponce.authToken<2) {
        // not logged in so redirect to login page with the return url
        
        return <Navigate to="/login" replace />;
      
      
    }

    
    return children;
}
export default PrivateRoute
