import React, { useState } from "react";

import { useEffect } from "react";
import { postverify } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import OTPInput, { ResendOTP } from "otp-input-react";
import logo from "../assets/logo.png"
import "./login.css";
import { useParams,useNavigate  } from "react-router-dom";
const Otpscreen = () => {
  let navigate = useNavigate();
    const [OTP, setOTP] = useState("");
    //BACK BUTTON PAGE
    const routeChange = () =>{ 
      let path = `/index.html`; 
      navigate(path);
    }
    // STORE USEDISPATCH FROM REDUX TO DISPATCH
    const dispatch = useDispatch();
    //USE STATE FOR OTP
    const responseData = useSelector((state) => state.reducer.verifyotp);
   //BUTTON FUNCTION FOR OTP FUNCTION
    const clickHandler = (e) => {
        e.preventDefault();
    
        const finalData = {
            token: OTP,
         
          
        };
        dispatch(postverify(finalData));
    
      };
       //NAVIGATE TO LOGIN PAGE
        useEffect(() => {
          if(responseData &&
            Object.keys(responseData).length > 0){
              if(responseData.success){
                navigate("/login ",{ state: OTP} );
              }
              else{
                alert(responseData.message);
              }
              
            }
          }, [responseData]);
   
  return (
    <>
     <div className="row banner">
    
    <div className="col-12">Please enter the email pin to verify your account </div>
    
    </div>
    
    <div className="otp_center">
    
    <img src={logo} alt="Paris" className="logoIMAG" />
   
   <div className="customcenter">
   <h6 className="otpcontent">
   Please enter the email pin to verify your account 
       </h6>
       <br>

       </br>
   <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={5} otpType="number" disabled={false} secure />
   
   <br></br>
   <button onClick={(e) => {
                   clickHandler(e);
                 }} type="submit" className="btn verifybtn btn btn-dark btn-block">Verify</button>


<br>
                    
                    </br>
                    <a href="/Forgot_password" className="forgotbtn">In case of PIN expiry, please click here.</a>
                    <button onClick={routeChange} type="submit" className="btn btn btn-block btn_back_home">Back To Home Page</button>

                   
   </div>

    </div>
    
   
  </>
  )
}

export default Otpscreen
