import React from 'react'
import './header.css';
import header_bg from'../assets/header_bg.png';

const Header = () => {
  return (
 
// MAIN BANNER
<div className="bg_imagec_cover">

<section class="header" style={{ backgroundImage: `url(${header_bg})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'}}>
    
    <p></p>
    <a class="btn-bgstroke"></a>
</section>
</div>


  )
}

export default Header
