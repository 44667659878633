import React, { useState  } from "react";
import { useEffect } from "react";
import { Postapilogin, clicklogout } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate  } from "react-router-dom";

const Logout = () => {
    let navigate = useNavigate();

    
    
   //get login responce data
    const dispatch = useDispatch();
    const responseData = useSelector((state) => state.reducer.loginresponse
    );
  
    const param1 = responseData.authToken
    console.log("auth token",param1)
    const navigateHome = () => {
      // 👇️ navigate to /
      navigate('/imp_form');
    };
    //LOGIN BUTTON FUNCTION
    const clickHandler = (e) => {
      e.preventDefault();
  
      
      /////SUBMIT BODY FOR LOGIN 
      
        alert("You have logged out successfully.")
      dispatch(clicklogout(param1)).then((result) => {
        console.log("result",result)
        if (result.success == true) {
            window.location.assign(
                `/index.html`
              );
         
        }else{
            window.location.assign(
                `/index.html`
              );
        }
      }).catch((error) => {
       // console.log("this is error",error)
       
      })
   
     
      
    };
  
  
    return (
      <div className='bg2'>
      <div className='wrap2'>
      <form>
  
      <div className="loginForm">
  
      <h3>Are you sure you want to logout?</h3>
      </div>
  
      <br>
        
      </br>
  
      
  
      <button onClick={(e) => {
                      clickHandler(e);
                    }} type="submit" className="btn btn-dark btn-block">Confirm</button>
      <button onClick={navigateHome}  className="btn btn-block">Back</button>
  
  
                   
  </form>
  </div>
  </div>
    )
}

export default Logout
