import React, { useState  } from "react";
import CollapsibleExample from "./Navbar"
import "./login.css";
import { useEffect } from "react";
import { Postapilogin, saveAuthToken,changepassword,userresetPassword } from "../redux/Actions/actions";

import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate  } from "react-router-dom";

function Resetpassword() {
  let navigate = useNavigate();
  //BACK PAGE FUNCTION
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/imp_form');
  };
  //staes for storage of data
  const [oldpassword, setoldpassword] = useState();
  const [newPassword, setnewpaswoord] = useState();
 //get login responce data
  const dispatch = useDispatch();

  //DATA STORED IN REDUX
  const loginResponce = useSelector((state) => state.reducer.loginresponse
  );
  const ResetPasswordResponse = useSelector((state) => state.reducer.resetPassword
  );


  const param1 = loginResponce.authToken
  useEffect(()=>{
    console.log("required",ResetPasswordResponse)
    if(ResetPasswordResponse){
      if (ResetPasswordResponse.success==true){
        console.log("password is true")
        alert(ResetPasswordResponse.message)
        navigate("/login")
      }
      else{
        console.log("password is false")
        alert(ResetPasswordResponse.message)
       
      }
    }
  },[ResetPasswordResponse])
  //LOGIN BUTTON FUNCTION
  const clickHandler = (e) => {
    {
      e.preventDefault();
   

      const finalData = {
        oldPassword:oldpassword,
       newPassword:newPassword 
      };
   
   
     
         
            if (oldpassword && newPassword  ){
              const pass =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,24}$/
              if(newPassword.length>7){
                  dispatch(userresetPassword(finalData,param1))}
                else{
                  alert("Password should have minimum 8 characters")
                }
    
             
              
             
            }else{
              alert("Fill all the required fields")
            }
         
     
     
     



    }
   
     
  };


  return (
    <>
    <CollapsibleExample/>
   
    <div className='bg2'>
    <div className='wrap2'>
    <form>

    <div className="loginForm">

    <h3>Reset Password</h3>
    </div>

    <div className="form-group">
        <label>Old Password</label>
        <input onChange={(e) => {
                        setoldpassword(e.target.value);
                      }} type="password" className="form-control" placeholder="Enter your old password" />
    </div>

    <div className="form-group">
        <label> New Password</label>
        <input onChange={(e) => {
                        setnewpaswoord(e.target.value);
                      }} type="password" className="form-control" placeholder="Enter your new password (Minimum Eight Characters)" />
    </div>

   

    <button onClick={(e) => {
                    clickHandler(e);
                  }} type="submit" className="btn btn-dark btn-block">Continue</button>
     <button onClick={navigateHome} type="submit" className="btn btn-block">Back</button>

                 
</form>
</div>
</div>
</>
  )
}

export default Resetpassword