export const GET_DETAILS="GET_DETAILS";
export const POST_DETAILS="POST_DETAILS";
export const POST_CART="POST_CART";
export const POST_LOGIN="POST_LOGIN";
export const POST_SIGNUP="POST_SIGNUP";
export const COUNTRY_LIST="COUNTRY_LIST";
export const POST_TID="POST_TID";
export const POST_VERIFY="POST_VERIFY";
export const IMPACT_POINT="IMPACT_POINT";
export const CREATE_SESION="CREATE_SESION";
export const SEND_FORGOT="SEND_FORGOT";
export const POST_FORGET_SEND_API="POST_FORGET_SEND_API";
export const TRANSECTION_DETAILS="TRANSECTION_DETAILS";
export const RESET_PASSWORD="RESET_PASSWORD";
export const FORGET_PASSWORD="FORGET_PASSWORD";
export const LOGIN_CHANGE_PASSWORD="LOGIN_CHANGE_PASSWORD";
export const LOGOUT_USER="LOGOUT_USER";
