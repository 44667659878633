import React, { useState } from "react";
import { useEffect } from "react";
import { Action, PostapiAction, PostTid, calculatePoint, createSesionipg, postInCart } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import "./forms.css";
import bg from "../assets/header_bg.png";
import akd from "../assets/akd.png";
import akm from "../assets/akm.png";
import Header from "./header";
import { useParams } from "react-router-dom";
import store from "../redux/reducer/store"
import { Route, Redirect } from "react-router";
import Modal from 'react-modal';
import Navbar from "../components/Navbar"



const Impforms = () => {

  //initialize state to store data
  const [CurrentIndex, setIndex] = React.useState(-1);
  const [counterNum, setNum] = useState(1);
  const [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [btn, setbtn] = useState(false);
  const [product, setproduct] = useState();

//initialize dispatch
  const dispatch = useDispatch();
  //GET LOGIN STORE VERIABLES
  const loginResponce = useSelector((state) => state.reducer.loginresponse);
  
  ///to get sesion id

  const sessionId = useSelector((state) => state.reducer.createsesion);
  //CREATE USER TRANSECTION ID
  const getTid = useSelector((state) => state.reducer.registerusertransection);  // {}
  //GET IMPECT POINTS
  const Points = useSelector((state) => state.reducer.impactpoint);
  //GET LIST OF AVAILIBLE PACKAGE
  const ngoDetailData = useSelector((state) => state.reducer.details);
  const Addtocart = useSelector((state) => state.reducer.addtocart);
  let tag;



//auth tocken store in param 1
  const param1 = loginResponce.authToken
 

  const requestId2 = Points


  



//product list fetch from api
const finalProducts = ngoDetailData?.data;
useEffect(()=>{
  
  if(sessionId!=null){
    setLoading(false);
    
                if(sessionId.sessionId && sessionId.sessionId.length>0){

                  window.location.assign(
                    //STAGE_URL
                    // `https://dev.payment.myimpactmeter.com/FRImpactorStage.html?requestId=${getTid.TID}&des=Flood Relief 2022&sId=${sessionId.sessionId}&vId=${sessionId.versionId}`
                    //LIVE_URL
                 
                    `https://payment.myimpactmeter.com/FRImpactor.html?requestId=${getTid.TID}&des=Flood Relief 2022&sId=${sessionId.sessionId}&vId=${sessionId.versionId}`
                    );
                }
            
  }
},[sessionId])
//CREATE SESSION USEEFFECT
useEffect(()=>{
  
  if(Addtocart!=null){
    let sesiondata = {
      requestId: getTid.TID,
      amount: finalProducts.item[CurrentIndex].price * counterNum,
      currency: "PKR",
      description: "Flood Relief Program 2022"

    };
    dispatch(createSesionipg(sesiondata, param1))
    
  }
},[Addtocart])
// POST IN ADD TO CART USE EFFECT
useEffect(() => {
  if(getTid!=null){
   
    let CartData;
    let finalData;
    let item;
    let ngo;
    let impactor;
    let sesiondata;
    item = {
      code: finalProducts.item[CurrentIndex].RC,
      id: finalProducts.item[CurrentIndex].id,
      name: finalProducts.item[CurrentIndex].name,
      amount: finalProducts.item[CurrentIndex].price * counterNum,
      qty: counterNum

    };
    //selected ngo data
    ngo = {
      ngoName: ngoDetailData.data.ngoName,
      ngoCode: ngoDetailData.data.ngoCode,
      moduleName: ngoDetailData.data.moduleName,
      contact: ngoDetailData.data.contact,
      email: ngoDetailData.data.email

    };
    //impactor data who wants to donate
    impactor = {
      _id: loginResponce.user._id,
      email: loginResponce.user.email,
      name: loginResponce.user.userName,
      contact: loginResponce.user.contact,
      profileImage: loginResponce.user.profileImage

    };
    // console.log("your impact point is",Points.impactPoints)
    // alert(`You Wil Get ${Points.impactPoints} Impact Credits For This Transection`)
//add to cart data
    CartData = {
      authToken: getTid.txAuthToken,
      userId: loginResponce.user._id,
      requestId: getTid.TID,
      impactPoints: Points.impactPoints,
      goldRate: Points.goldRate,
      mimSharePercent: Points.mimSharePercent,
      // mimShareAmount:(finalProducts.item[CurrentIndex].price * counterNum) * 0.08,
      mimShareAmount:(finalProducts.item[CurrentIndex].price * counterNum) * (Points.mimSharePercent/100),

      moduleName: ngoDetailData.data.moduleName,
      amount: finalProducts.item[CurrentIndex].price * counterNum,
      impactor: impactor,
      ngo: ngo,
      item: item,
    };
  //create sesion data
  alert(`You will get ${Points.impactPoints} impact credits for this transaction`)
    dispatch(postInCart(CartData, param1))
  }
  
  



}, [getTid]);
//POST ID USEEFFECT
  useEffect(() => {
   
    if(Points!=null){
      dispatch(PostTid(param1, finalProducts.item[CurrentIndex].RC));
    }
  


  }, [Points]);

  //get products list
  useEffect(() => {

    dispatch(Action());

  }, []);


  //selected product for data manipulation
  const test = (event) => {
    event.preventDefault();
    setbtn(true)
    let g = event.target.value; // TP 
    setproduct(g);
    setIndex(g);
    
  };

  // optionHandler
  const optionsHandler = (e) => {

 
  }

  //CACULATE IMPACT POINTS
  const clickHandler = async (e) => {
  if(!loading){
    e.preventDefault();
    setLoading(true);
    dispatch(calculatePoint(finalProducts.item[CurrentIndex].price * counterNum, param1));
  }
    

    
  };





///name display on top
  const params = useParams();
  const { name } = params;
  

  //for quantity selection
  //INCREMENT FUNCTION
  const incrementNumber = (event) => {
    event.preventDefault();
    let g = counterNum + 1;

    

    setNum(g);
    
  };
  //DECRIMENT FUNTION
  const decrementNumber = (event) => {
    event.preventDefault();
    let h = counterNum - 1;
    counterNum == h<1 ? setNum(1) : setNum(h);
   
  };

  

  return (
    <>
      <Navbar/>
      <Header />
      <div className="container">
          <div className="col-12 wrap">
              <h4 className="mb-3 main-heading">{name}</h4>
              <form className="needs-validation checkout" onSubmit={clickHandler}>

                <div className="form-group">
                  <label htmlFor="country" className="headingleft txt">
                  Services
                  </label>

                  <select
                    onChange={test}

                    className="custom-select select d-block w-100 br=4"
                    id="country"
                    required
                  >
                     {product?"":<option value>Choose Service...</option>}
                    {finalProducts?.item?.map((x, index) => {
                      return (
                        <option key={index} value={index} > { x.name} </option>
                      );
                    })}
                  </select>

                  <div className="invalid-feedback">
                    Please select a valid Service.
                  </div>
                </div>
              <div className="form-group spaces ">
                    <label htmlFor="state" className="headingleft txt">
                      Quantity
                    </label>
                    <div class="qty custom_qnty">
                        <span onClick={incrementNumber} class="minus bg-dark">+</span>
                        <input  className="count" value={counterNum} name="qty" id="qty_number" defaultValue={1} />

                        <span onClick={decrementNumber} class="plus bg-dark">-</span>
                    </div>
                  {/* <div className="row" style={{    width: "8%"}}>
                      <button
                      
                        onClick={decrementNumber}
                        className="plussection"
                      >
                        -
                      </button>
                      
                      <input className="countdiv" type="text" value={counterNum} />
                      <button onClick={incrementNumber} class="plussection">
                        +
                      </button>
                  </div> */}
                </div>

                <div className="form-group spaces">
                    <label for="price" htmlFor="price" className="headingleft txt">Total</label>
                  <div className="">
                  <label id="price" className="txt">{CurrentIndex<0? "PKR 0":"PKR " + (finalProducts.item[CurrentIndex].price * counterNum).toLocaleString()} </label>
                  </div>
                </div>
                <hr  />
                {btn? <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                >
                  Donate now
                </button>:""
}
               
                {/* fawad */}
                <div className="signUpTextDiv">
      *By continuing, you are accepting our Terms and Conditions, EULA and Privacy Policy
      </div>
      <div className="signUpTextDiv">
      Please email <a href="mailto:support@myimpactmeter.com">support@myimpactmeter.com</a> for feedback or in case of any issues.
      </div>
                <label className="label_loading" >
                  {loading?"Processing....":" "} 
                </label>
                
              </form>
          </div>
      </div>
      <br>

</br>
<br>

</br>
<br>

</br>

<div className="footer row">
<div className="col-4"><a className="footer_text" href="https://www.myimpactmeter.com/terms-conditions/">Terms & Conditions</a></div>
<div className="col-4"><a className="footer_text" href="https://www.myimpactmeter.com/end-user-license-agreement/  ">EULA</a></div>
<div className="col-4"><a className="footer_text" href="https://www.myimpactmeter.com/privacy-policy-2/  ">Privacy Policy</a></div>

</div>
    </>
  );
};

export default Impforms;
