import axios from 'axios';
import POST_LOGIN from '../redux/reducer/type'



export async function AxiosRequest(url,method,headers,params){


    return params?axios({
        url:url,
        method:method,
        headers:headers,
        data:params,
        timeout:10000
    })
    :axios({
        url:url,
        method:method,
        headers:headers,
        data:{},
        timeout:10000
    });
}
///GET PRODUCTS DETAIL
const GetApiDetails=()=>{
    const headers ={
        'content-type':'application/json',
    };
    //STAGE_URL
    // return AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/FRP/getItems?id=AKM','GET',headers,{})
    //FINAL_URL
    return AxiosRequest('https://app.myimpactmeter.com/api/v2/FRP/getItems?id=AKM','GET',headers,{})
};


///GET TRANSECTION HISTORY

const Gettransection=(p1)=>{
    console.log("get api ",p1)
    const headers ={
        'content-type':'application/json',
        'Authorization':'Bearer '+p1
    };
    //STAGE_URL
 //  return AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/impactor/getTransactions?skipped=0&limited=5','GET',headers,{})
    //FINAL_URL
    return AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/getTransactions?skipped=0&limited=5','GET',headers,{})
};
//GUEST USER DATA API 
const postApiDetails=(data)=>{
    // console.log("post data ",data)
    const headers ={
        'content-type':'application/json',
    };
    //STAGE_URL

    //  return AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/ipg/createSessionRand','POST',headers,data={"data": data})
    //FINAL_URL
    return AxiosRequest('https://app.myimpactmeter.com/api/v2/ipg/createSessionRand','POST',headers,data={"data": data})
    
};

//Set PASSWORD
const postApiSETPASSWORD=async (data)=>{
    // console.log("post data ",data)
    const headers ={
        'content-type':'application/json',
    };
    //STAGE_URL

    //  return await AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/impactor/setForgotPassword','POST',headers,data)
  //FINAL_URL
    return await AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/setForgotPassword','POST',headers,data)
};
//ADD TO CART
const addToCart= async (data,p1) =>{
    console.log("add to cart  data ",data)
    const headers ={
        'content-type':'application/json',
        'Authorization':'Bearer '+p1
        
    };
    //STAGE_URL

    //  return await AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/impactor/addToCart','POST',headers,data)
    //FINAL_URL
    return await AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/addToCart','POST',headers,data)
    
};
//POST API LOGIN
const postApilogin=(data)=>{
    
    const headers ={
        'content-type':'application/json',
    };
    
    //STAGE_URL
//  return AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/impactor/login','POST',headers,data)
    //FINAL_URL    
    return AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/login','POST',headers,data)
    
};
//POST API SIGNUP
const postApisignup=async (data)=>{
    
    const headers ={
        'content-type':'application/json',
    };
    //STAGE_URL

    // return await AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/impactor/registerStep1','POST',headers,data)
    //FINAL_URL
    return await AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/registerStep1','POST',headers,data)
    
};

//POST API FORGET PASSWORD SEND OTP
const postsendpassotp=async (data)=>{
    
    const headers ={
        'content-type':'application/json',
    };
    ////STAGE_URL

    // return await AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/impactor/sendSMSOTP','POST',headers,data)
    //FINAL_URL
    return await AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/sendSMSOTP','POST',headers,data)
};
///SEND OTP
const postverifyotp=(data)=>{
    
    const headers ={
        'content-type':'application/json',
    };
    //STAGE_URL

    // return AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/impactor/verify','POST',headers,data)
    //FINAL_URL
    return AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/verify','POST',headers,data)
    
};
//change password
const changepassword=async (data,p1)=>{
    console.log("post data ",data,p1)
    const headers ={
        'content-type':'application/json',
        'Authorization':'Bearer '+p1
    };
    //STAGE_URL

    // return await AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/impactor/resetPassword','POST',headers,data)
    
    //FINAL_URL
    return await AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/resetPassword','POST',headers,data)
};
///CREATE TID
const posttid=(p1,p2)=>{
    // console.log("post data tid in axio ",p1,p2)
    const headers ={
        'content-type':'application/json',
        'Authorization':'Bearer '+p1
    };
    //STAGE_URL

    // return AxiosRequest(`https://app.myimpactmeter.com/api/v2/stage/FRP/createFRPTID?ngoCode=AKM&rfc=${p2}`,'GET',headers,{})
    //FINAL_URL
    return AxiosRequest(`https://app.myimpactmeter.com/api/v2/FRP/createFRPTID?ngoCode=AKM&rfc=${p2}`,'GET',headers,{})
    
};

//CALCULATE IMPACT POINTS
const calculateimpactpoint=(amnt,param1)=>{
    console.log("post in impact point ",typeof(amnt),amnt,param1)
    // const headers ={
       
    // };
    //STAGE_URL

    //  return AxiosRequest(`https://app.myimpactmeter.com/api/v2/stage/impactor/getImpactPoints?amount=${amnt}`,'GET', {'content-type':'application/json',
    //  'Authorization':'Bearer '+param1},{})
    //FINAL_URL
    return AxiosRequest(`https://app.myimpactmeter.com/api/v2/impactor/getImpactPoints?amount=${amnt}`,'GET', {'content-type':'application/json',
    'Authorization':'Bearer '+param1},{})
    
};

///GET logout

const logout=(p1)=>{
    console.log("logout ",p1)
    const headers ={
        'content-type':'application/json',
        'Authorization':'Bearer '+p1
    };
    //STAGE_URL
   // return AxiosRequest('https://app.myimpactmeter.com/api/v2/dev/impactor/logout','GET',headers,{})
   //FINAL_URL
   return AxiosRequest('https://app.myimpactmeter.com/api/v2/impactor/logout','GET',headers,{})
};

///GET COUNTRIES LIST
const Getcountrieslist=()=>{
    const headers ={
        'content-type':'application/json',
    };
    //STAGE_URL

     return AxiosRequest('https://app.myimpactmeter.com/api/v2/stage/admin/countries','GET',headers,{})
//    return AxiosRequest('https://app.myimpactmeter.com/api/v2/admin/countries','GET',headers,{})
};
///CREATE SESSION
const createsesion= async (data,p1)=>{
   
    const headers ={
        
    };

    try{
        //STAGE_URL

        // return axios.post('https://app.myimpactmeter.com/api/v2/stage/ipg/createSessionBAFIPGFR',{"data": {...data}}, {headers:{'Authorization':'Bearer '+p1}})
       //FINAL_URL
        return axios.post('https://app.myimpactmeter.com/api/v2/ipg/createSessionBAFIPGFR',{"data": {...data}}, {headers:{'Authorization':'Bearer '+p1}})
    }catch(error){
        console.log("errro:", error)
    }
     
    // return AxiosRequest('https://app.myimpactmeter.com/api/v2/dev/ipg/createSessionBAFIPG','POST',headers,data={"data": data})
    
};

export {GetApiDetails,postApiDetails,addToCart,postApilogin,posttid,postApisignup,postverifyotp,calculateimpactpoint,createsesion,postsendpassotp,postApiSETPASSWORD,Gettransection,changepassword,Getcountrieslist,logout};
