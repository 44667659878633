
import React, { useState  } from "react";

import "./login.css";
import { useEffect } from "react";
import { Postapilogin, saveAuthToken } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate  } from "react-router-dom";
const Login = () => {

  //initialize navigate
  let navigate = useNavigate();
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  //staes for storage of data
  const [email, setemail] = useState();
  const [Password, setpaswoord] = useState();
 //get login responce data
  const dispatch = useDispatch();
  const responseData = useSelector((state) => state.reducer.loginresponse
  );

  
  //LOGIN BUTTON FUNCTION
  const clickHandler = (e) => {
    e.preventDefault();

    const finalData = {
      email: email,
      password:Password,
      keepMeLogin:true 
      
    };
    /////SUBMIT BODY FOR LOGIN 
    
      
    dispatch(Postapilogin(finalData)).then((result) => {
      if (result == true) {
        navigate("/imp_form");
      }else{
       
        alert("Please fill the correct information!")
      }
    }).catch((error) => {
    
      alert("Please fill the correct information!")
    })
 
   
    
  };


  return (
    <div className='bg2'>
    <div className='wrap2'>
    <form>

    <div className="loginForm">

    <h3>Log in</h3>
    </div>

    <div className="form-group">
        <label className="txt">Email Address </label>
        <input onChange={(e) => {
                        setemail(e.target.value.trim());
                      }} type="email" className="form-control" placeholder="Enter your email address" />
    </div>

    <div className="form-group">
        <label className="txt">Password </label>
        <input onChange={(e) => {
                        setpaswoord(e.target.value);
                      }} type="password" className="form-control" placeholder="Enter your password" />
    </div>

    

    <button onClick={(e) => {
                    clickHandler(e);
                  }} type="submit" className="btn btn-dark btn-block">Log in</button>


                  <a href="/Forgot_password" className="forgotbtn">Forgot Password</a>
                  <button onClick={navigateHome}  className="btn btn-block">Back To Home Page</button>

                  
</form>
</div>
</div>
  )
}

export default Login
