
import './App.css';
import Home from './components/home'

import Forms  from './components/forms';
import Landing_page  from './components/landing_page';
import Login  from './components/login';
import Signup  from './components/signup';
import Impforms  from './components/impactorform';
import Otpscreen  from './components/otpscreen';
import Confirm  from './components/confirm';
import ImpactorConfirm  from './components/impactorconfirm';
import Forgot_password  from './components/forgot_password';
import Passotp  from './components/passotp';
import Cnfrmpassreset  from './components/cnfrmpassreset';
import Transection  from './components/transection';
import Resetpassword  from './components/resetpassword';
import Logout  from './components/logout';
import {BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';


import   PrivateRoute  from './components/privateroute';



function App() {
  return (
    
    <>

   <Routes>
{/* ROUTE FOR MAIN LANDING.JS PAGE */}
   <Route path="/" element={<Landing_page/>} exact />
   {/* ROUTE FOR MAIN LANDING PAGE */}
   <Route
        path="/index.html"
        element={<Landing_page/>} exact /> 
    
   <Route path="/login" element={<Login/>} exact />


{/* ROUTE FOR IMPACTFORM.JS PAGE */}
   <Route 
   path="/imp_form" 
   element={ 
     <PrivateRoute><Impforms/></PrivateRoute>}  />

   {/* ROUTE FOR  SIGNUP.JS PAGE */}

   <Route path="/signup" element={<Signup/>} exact />
   {/* ROUTE FOR HOME.JS PAGE */}
    <Route path="/home" element={<Home/>} exact />
    {/* ROUTE FOR FORM.JS PAGE */}
    <Route path="/checkout/:name" element={<Forms/>} />
    {/* ROUTE FOR OTPSCREEN.JS PAGE */}
    <Route path="/otp" element={<Otpscreen/>} />
    {/* ROUTE FOR CONFIRM.JS PAGE */}
    <Route path="/confirm" element={<Confirm/>} />
    {/* ROUTE FOR CONFIRMIMPACTOR.JS PAGE */}
    <Route path="/confirm_impactor" element={<ImpactorConfirm/>} />
    {/* ROUTE FOR FORGET PASSWORD.JS PAGE */}
    <Route path="/Forgot_password" element={<Forgot_password/>} />
    {/* ROUTE FOR PASSOTP.JS PAGE */}
    <Route path="/Passotp" element={ <Passotp/>} />
    {/* ROUTE FOR CONFIRMPASSRESET.JS PAGE */}
    <Route path="/Cnfrmpassreset" element={<Cnfrmpassreset/>} />
    {/* ROUTE FOR TRANSACTION.JS PAGE */}
    <Route path="/transaction" element={ <PrivateRoute><Transection/></PrivateRoute>} />
    {/* ROUTE FOR RESETPASSWORD.JS PAGE */}
    <Route path="/Resetpassword"  element={ <PrivateRoute><Resetpassword/></PrivateRoute>} />
    {/* ROUTE FOR LOGOUT.JS PAGE */}
    <Route path="/logout" element={ <PrivateRoute><Logout/></PrivateRoute>} />

    <Route  element={Error} />

     {/* <Home/>
     <Forms/> */}
     
     </Routes>
     
     </>
  );
}

export default App;
