import RootReducer  from "./roorReducer";
import {composeWithDevTools} from'redux-devtools-extension'
import {createStore } from 'redux';
import { applyMiddleware } from "redux";


import thunk from'redux-thunk'

const store=createStore(RootReducer,composeWithDevTools(applyMiddleware(thunk)));


export default store;

