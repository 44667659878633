import React, { useState  } from "react";

import "./login.css";
import { useEffect } from "react";
import { Postapilogin, saveAuthToken,postsetpassword } from "../redux/Actions/actions";

import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate ,useLocation  } from "react-router-dom";
import { ConsoleSqlOutlined } from "@ant-design/icons";

function Cnfrmpassreset() {
  //initialize navigate
  let navigate = useNavigate();
  const { state } = useLocation();
  const OTP = state;
  
  //staes for storage of data
  const [confirmpass, setconfirmpass] = useState();
  const [Password, setpaswoord] = useState();
 //get login responce data
  const dispatch = useDispatch();

  const loginResponce = useSelector((state) => state.reducer.verifyotp);
  
  const sendotp = useSelector((state) => state.reducer.sendotp);
//BACK BUTTON FUNCTION TO REDIRECT LOGIN PAGE
 useEffect(()=>{
  if(sendotp){
    if(sendotp.success){
      alert("Your Password Reset Successfully")
      navigate("/login");
    }
    else{
      alert(sendotp.message)
      
    }
  }
  
 },[sendotp])
  //LOGIN BUTTON FUNCTION
  const clickHandler = (e) => {
    e.preventDefault();


   

    
    /////SUBMIT BODY FOR LOGIN ////
    if(Password==confirmpass){
      const finalData = {
        userId:loginResponce.data.userId,
        token:OTP,
        newPassword:Password
          
  
        
        
      };
      const pass =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,24}$/
      if(Password.length>7){dispatch(postsetpassword(finalData))}
      else{alert("Password should have minimum 8 characters")}
    
   
        
    }
    else{
     alert("Passwords do not match")
    }
    
   
 
   
    
  };


  return (
    <div className='bg2'>
    <div className='wrap2'>
    <form>

    <div className="loginForm">

    <h3>Set New Password</h3>
    </div>

    

    <div className="form-group">
        <label>New Password</label>
        <input onChange={(e) => {
                        setpaswoord(e.target.value);
                      }} type="password" className="form-control" placeholder="Enter your new password" />
    </div>

    <div className="form-group">
        <label>Confirm New Password</label>
        <input onChange={(e) => {
                        setconfirmpass(e.target.value);
                      }} type="password" className="form-control" placeholder="Enter your confirm password" />
    </div>

    

    <button onClick={(e) => {
                    clickHandler(e);
                  }} type="submit" className="btn btn-dark btn-block">Set Password</button>

              
</form>
</div>
</div>
  )
}

export default Cnfrmpassreset
