import React from 'react'
import moment from 'moment';
import {useEffect} from 'react'
import "./transection.css";
import CollapsibleExample from "./Navbar"
import { transectiondetail, PostapiAction } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate  } from "react-router-dom";
function Transection() {
  const isItem = true;
  const dispatch = useDispatch();
  //data from storejs
  const loginResponce = useSelector((state) => state.reducer.loginresponse);
 
  let responseData = useSelector((state) => state.reducer.transectiondetails);

  const navigate = useNavigate();
  
//BACK BUTTON FUNCTION
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/imp_form');
  };
  const param1 = loginResponce.authToken
 
  const final = responseData?.data;
 ////GET TRANSECTION BY PASSING AUTH TOKEN IN TRANSECTIONDETAIL
  
  useEffect(() => {
    console.log("param1",param1)
    dispatch(transectiondetail(param1));
  }, [ transectiondetail]);
  
  return <>
   <CollapsibleExample/>
   <div class="main-container">
  <div class="heading1">
  
    <h2 class="heading__title">Transaction History</h2>
   
  </div>
  <div class="cards">
   {
    final ?  final?.map((x, index) => {
      return (
        <>
       
        <div className="row">
      <div className="column">
        <div className="card">
          
        <div className='row cardrow carddate'><h5 className='cardstart'>Date</h5><h5 className='cardend'>{moment(parseInt(x.createdAt)).format("YYYY-MM-DD")}</h5></div>
          <div className='row cardrow'><h5  className='cardstart'>Order ID</h5><h5 className='cardend'>{x.requestId}</h5></div>
          <div className='row cardrow'><h5  className='cardstart'>Transaction Amount</h5><h5 className='cardend'>PKR {x.amount.toLocaleString()}</h5></div>
         
          <div className='row cardrow'>
            <h5  className='cardstart'>Payment Purpose</h5><h5 className='cardend'>{x.moduleName=="FR"?"FLOOD RELIEF":x.moduleName}</h5></div>
          <div className='row cardrow'><h5  className='cardstart'>Payment Method</h5><h5 className='cardend'>{x.transactionDetail.paymentMethod}</h5></div>
          <div className='row cardrow'><h5  className='cardstart'>Impact Points</h5><h5 className='cardend'>{x.transactionDetail.transactionImpactPoints}</h5></div>
          

          
           
         
        </div>
      </div>
    </div>
      
        </>
        
      );
    })
    :
    
     <div className="transaction_text">
     <h1> No Transactions Found</h1>
     </div>
    
    
   } 
  </div>
  <center>
  <button className='back-button' onClick={navigateHome}>Back</button>
  </center>
</div>
  </>
   
}

export default Transection
