import {GET_DETAILS,POST_DETAILS,POST_LOGIN,POST_SIGNUP,POST_FORGET_SEND_API,FORGET_PASSWORD} from './type'

const initialstate={
    details:[],
    countries:[],
    getresponse:{},
    akmBundleData:[],
    loginresponse:{},
    signupresponse:{},
    verifyotp:null,
    authToken:{},
    registerusertransection:null,
    impactpoint:null,
    addtocart:null,
    createsesion:null,
    loginchangepassword:null,
    resetPassword:null,
    forgetPassword:null,
    sendotp:null,
    transectiondetails:[],
    postforgetsendapi:null,
    logout:null

};



const Reducer=  (state=initialstate,action)=>{
    
    switch(action.type){
        

        
        case "GET_DETAILS":
            

            return {
               ...state,
                details:action.payload
            }
        case "POST_DETAILS":
            //console.log("data nedded",action)

            return {
                ...state,
                getresponse:action.payload,
                
            }
        case "AKM_BUNDLE_DATA":
            //console.log("post_reached",action)

            return {
                ...state,
                akmBundleData:action.payload,
                
            }
        case "POST_LOGIN":
            console.log("LOGIN DATA",action)

            return {
                ...state,
                loginresponse:action.payload,

            }  
        case "COUNTRY_LIST":
            console.log("COUNTRY",action)

            return {
                ...state,
                countries:action.payload,

            }  
        case "POST_SIGNUP":
            console.log("data nedded",action)

            return {
                ...state,
                signupresponse:action.payload,
                
            }
           
        case "AUTH_TOKEN":
            //console.log("AUTH_TOKEN ",action)

            return {
                ...state,
                authToken:action.payload,
                
            }
           
        case "POST_VERIFY":
            console.log("data nedded",action)

            return {
                ...state,
                verifyotp:action.payload,
                
            }  
        case "POST_TID":
            

                return {
                  ...state,
                   registerusertransection:action.payload,
                }
        case "IMPACT_POINT":
    

            return {
                ...state,
                impactpoint:action.payload,
            }
        case "POST_CART":


        return {
            ...state,
            addtocart:action.payload,
        }
        case "CREATE_SESION":


            return {
                ...state,
                createsesion:action.payload,
        }
        
        case "SEND_FORGOT":


            return {
                ...state,
                sendotp:action.payload,
        }
        case "POST_FORGET_SEND_API":


            return {
                ...state,
                postforgetsendapi:action.payload,
        }
        case "TRANSECTION_DETAILS":


            return {
                ...state,
                transectiondetails:action.payload,
        }
        case "RESET_PASSWORD":
            return {
                ...state,
                resetPassword:action.payload,
            }
        case "FORGET_PASSWORD":
            return {
                ...state,
                forgetPassword:action.payload,
            }
        case "LOGIN_CHANGE_PASSWORD":


            return {
                ...state,
                loginchangepassword:action.payload,
        }
        case "LOGOUT_USER":


            return {
                ...state,
                logout:action.payload,
        }
        default:
            return state
    }
};
export default Reducer;