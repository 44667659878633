import Container from 'react-bootstrap/Container';
import React, { useState  } from "react";
import { useEffect } from "react";
import { Postapilogin, saveAuthToken } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate  } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../assets/logo.png"
import { LinkContainer } from "react-router-bootstrap";
import "./login.css";

function CollapsibleExample() {
  const dispatch = useDispatch();
  // staes for storage of data
  const responseData = useSelector((state) => state.reducer.loginresponse
  );
console.log("data",responseData.user.userName)
  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="https://www.myimpactmeter.com/">  <img
              src={logo}
              width="100%"
              
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <LinkContainer to="/">
            <Nav.Link >Home</Nav.Link>
            </LinkContainer>
          <LinkContainer to="/transaction">
          <Nav.Link >Transaction History</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Resetpassword">
            <Nav.Link >Reset Password</Nav.Link>
            </LinkContainer>
            
            

            <LinkContainer to="/logout">
            <Nav.Link >Logout</Nav.Link>
            </LinkContainer>
            <Nav.Link >Hi, {responseData.user.userName.toUpperCase()}</Nav.Link>
          </Nav>
          
         
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;