import React from 'react'

import './landing.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import mimIcon from "../assets/mimIcon.png"
import AlKhidmat from "../assets/Alkhidmat.png";


const Landing_page = () => {
  return (
    <>
    <Container>
    <h1 className='signUpTextDiv'>My Impact Meter</h1>
    <h3 className='signUpTextDivdown'>AKFP Flood Relief Portal</h3>
    <br/>
    
    <Row>
 {/* LEFT PART CONTAIN BUTTON AND CONTENT  */}Z
    <Col className='contentcol'><div className="centerlogo_logo">
<a href='https://www.myimpactmeter.com/'><img  src={mimIcon} alt="My Impact Meter" className="centerlogo"></img></a>


<a href='https://www.myimpactmeter.com/flood-relief/'><img  src={AlKhidmat} alt="My Impact Meter" className="centerlogo"></img></a> 

</div>

  
 

  
  




<h4 className='signUpTextDivdown'>ACT NOW to Contribute Towards Flood Relief</h4>

  
  <div className="signUpTextDiv">
  
  <a href='/signup' className="home-button">Sign Up</a>
  <a href='/login' className="home-button">Log In</a>
  </div> 
    <div className="signUpTextDiv">
    <a href='/checkout/Alkhidmat Foundation Pakistan' className="continueGuest">Continue as a Guest</a>
    </div>
    <h5 className='note'>*If you continue as guest, you will not get Impact Credits/Rewards</h5>

  
   
    

   



</Col>
 {/* LEFT PART CONTAIN BUTTON AND CONTENT  */}
      <Col>
     
      <iframe width="420" height="345" src="https://www.youtube.com/embed/IsbELjgfWSI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</Col>
      
    </Row>
   
  </Container>
<div className="image_landing_bg">
<section className="section-one"> 
   
      

  
  

  </section> 
  
</div>
</>
  )
}

export default Landing_page