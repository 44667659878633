import React, { useState } from "react";
import { useEffect } from "react";
import { Action, PostapiAction } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";


import Header from "../components/header";

import { useParams,useNavigate  } from "react-router-dom";



const Forms = () => {

  //initialized states to store data
  const [product, setproduct] = React.useState();
  const [userName, setuserName] = useState();
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [prname, setprname] = useState("");
  const [counterNum, setNum] = useState(1);
  const [amount, setAmount] = useState(1);
  const [match, setmatch] = useState(1);
  const [requiredata, setrequiredata] = useState(1);
  const [tottalAmount, setTottalAmount] = useState(0);
  const [isRedirectable, setIsRedirectable] = useState(false);
  const [loading, setLoading] = useState(false);
//initialized dispatch
  const dispatch = useDispatch();
  //data from storejs
  const responseData = useSelector((state) => state.reducer.details);
  let responseData1 = useSelector((state) => state.reducer.getresponse);
  const final = responseData?.data;
  useEffect(() => {
   
  }, []);
////USEEFFECT FOR GET SERVICES
  useEffect(() => {

    dispatch(Action());
  }, [PostapiAction, Action]);

  ///BACK BUTTON FUNCTION
  let navigate = useNavigate();
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  //function for evaluate amount 
  const test = (event) => {
    event.preventDefault();
    let g = event.target.value;
    
    setproduct(g);
    const pr = g.split(/[\s,]+/);
    console.log("ugkjgkj",g)
    console.log("ugkjgkj",prn)
   
    const prn=pr[pr.length -2];
    
    
  };
// STORE SELECTED SERVICE IN DRPSELECT
const drpselect=product

let selectedpr;

let arr=[];
if (drpselect !== "undefined"){
  selectedpr = final?.item?.forEach(function (item) {
     item.name === drpselect ? arr.push(item.price) : arr= arr;
  })
}
let price=arr[0]









////submit button function
  const clickHandler = (e) => {
    if(!loading){
      e.preventDefault();
   
console.log(prname)
      const finalData = {
        email,
        name: userName,
        contact: phone,
        amount:price*counterNum,
        currency: "PKR",
        ngoEmail:final.email,
        ngoContact:final.contact,
        ngoName:final.ngoName,
        itemQty:counterNum,
        itemName:product,
      };
    console.log("final data",finalData)
//MOBILE NO REGEX
    const num=/^[+]?[0-9]+([0-9 ])*$/
    //FULLNAME REGEX
    const name = /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/
     ///EMAIL REGEX
      const reg= /^(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
      if (num.test(phone)){
        if(name.test(userName)){
          if (reg.test(email)){
            // console.log("finalData",finalData)
            
            if (email && userName && phone&& drpselect ){
              setLoading(true)
              dispatch(PostapiAction(finalData));
            }else{
              alert("Fill all the required fields")
            }
          }else{
            alert("Please enter your valid email address")
          }
        }else{
          alert("Full name can only contain alphabets and spaces")
        }
      }else{
        alert("Mobile Number should only consist of numbers, + sign or spaces.")
      }
      
      
     



    }
   
      
  };
  const params = useParams();
  const { name } = params;

  /// increment function ///
    const incrementNumber = (event) => {
    event.preventDefault();
    let g = counterNum + 1;
    setNum(g);
    const pr = product.split(/[\s,]+/);
    const last = pr[pr.length - 1];
    const amount = last * g;
    setAmount(amount);
    setTottalAmount(amount);
  };
//decrement function
  const decrementNumber = (event) => {
    event.preventDefault();
    let h = counterNum - 1;
    counterNum == 1 ? setNum(1) : setNum(h);
    const pr = product.split(/[\s,]+/);
    const last = pr[pr.length - 1];
    //console.log("jhfghgfhjfhgjhgjhg", last);
    const amount = last * h;
    //console.log("minusssss", amount);
    setAmount(amount);
    setTottalAmount(amount);
  };
//redirect to bank url
  useEffect(() => {
    if(responseData1 &&
      Object.keys(responseData1).length > 0){
        setLoading(false);
        window.location.assign(
          //STAGE_URL FOR REDIRECTIN PAYMENT GATE WAY
          //`https://dev.payment.myimpactmeter.com/floodreliefpay.html?requestId=${responseData1.requestId}&sId=${responseData1.sessionId}&vId=${responseData1.versionId}`
          //LIVE_URL FOR REDIRECTIN PAYMENT GATE WAY
          `https://payment.myimpactmeter.com/floodreliefpay.html?requestId=${responseData1.requestId}&sId=${responseData1.sessionId}&vId=${responseData1.versionId}`
        );
        
        
    }
  }, [responseData1]);

  return (
    <>
  
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12 wrap">
              <h4 className="mb-3 main-heading">{name}</h4>
              <form className="needs-validation checkout">
               
                <div className="form-group country">
                  <label htmlFor="country" className="headingleft txt">
                  Services 
                  </label>

                  <select
                    onChange={test}
                    className="custom-select select d-block w-100 br=4"
                    id="country"
                    required
                  >
                    {product?"":<option value>Choose Service... </option>}
                    
                    {final?.item?.map((x, index) => {
                      return (
                        <option key={index}>{x.name }</option>
                      );
                    })}
                  </select>

                  <div className="invalid-feedback">
                    Please select a valid Product.
                  </div>
                </div>
                <div className="form-group country">
                  <label htmlFor="username" className="headingleft txt">
                  Full Name
                  </label>
                  {/* <div className="input-group"> */}
                    {/* <div className="input-group-prepend select"></div> */}
                    <input
                    
                      onChange={(e) => {
                        
                        setuserName(e.target.value.trim());
                      }}
                      placeholder="Enter your full name (Only Alphabets and Space)"
                      type="text"
                      className="form-control user"
                      id="username"
                      required
                    />
                    {/* <div className="invalid-feedback" style={{ width: "100%" }}>
                      Your username is required.
                    </div> */}
                  {/* </div> */}
                </div>
                <div className="form-group country">
                  <label htmlFor="username" className="headingleft txt">
                  Mobile Number 
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend select"></div>
                    <input
                      onChange={(e) => {
                        setphone(e.target.value.trim());
                      }}
                     
                      className="form-control user"
                      id="username"
                      placeholder="Enter your mobile number"
                      required
                    />
                    <div className="invalid-feedback" style={{ width: "100%" }}>
                      Your username is required.
                    </div>
                  </div>
                </div>
                <div className="form-group country">
                  <label htmlFor="email" className="headingleft txt">
                  Email Address{" "}
                  </label>
                  <input
                    onChange={(e) => {
                      setemail(e.target.value.trim());
                    }}
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter your email address"
                    required
                  />
                  <div className="invalid-feedback" style={{ width: "100%" }}>
                    Please enter a valid email address for shipping updates.
                  </div>
                </div>
                <div className="form-group spaces ">
                    <label htmlFor="state" className="headingleft txt">
                      Quantity
                    </label>
                    <div class="qty custom_qnty">
                        <span onClick={incrementNumber} class="minus bg-dark">+</span>

                        <input  className="count" value={counterNum} name="qty" id="qty_number" defaultValue={1} readonly/>
                        <div></div>
                        <span onClick={decrementNumber} class="plus bg-dark">-</span>
                    </div>
                  {/* <div className="row" style={{    width: "8%"}}>
                      <button
                      
                        onClick={decrementNumber}
                        className="plussection"
                      >
                        -
                      </button>
                      
                      <input className="countdiv" type="text" value={counterNum} />
                      <button onClick={incrementNumber} class="plussection">
                        +
                      </button>
                  </div> */}
                </div>

                <div className="form-group spaces">
                    <label for="price" htmlFor="price" className="headingleft txt">Total</label>
                  <div className="">
                  <label id="price"  className="txt">PKR {(price*counterNum)>=0?(price*counterNum).toLocaleString():"0"} </label>

                  </div>
                </div>



                <hr className="form-group line" />
                <button
                  className="btn btn-primary  btn-lg btn-block btn"
                  type="submit"
                  onClick={(e) => {
                    clickHandler(e);
                  }}
                >
                  Donate Now
                </button>
                {/* fawad */}

                <div className="signUpTextDiv">
      *By continuing, you are accepting our Terms and Conditions, EULA and Privacy Policy
      </div>
      <div className="signUpTextDiv">
      Please email <a href="mailto:support@myimpactmeter.com">support@myimpactmeter.com</a> for feedback or in case of any issues.
      </div>
                <label className="label_loading" >
                  {loading?"Processing....":" "} 
                </label>
                <button onClick={navigateHome} className="btn btn-block">Back To Home Page</button>
              </form>
          </div>
        </div>
      </div>
      <br>

      </br>
      <br>

      </br>
      <br>

      </br>

      <div className="footer row">
      <div className="col-4"><a className="footer_text" href="https://www.myimpactmeter.com/terms-conditions/">Terms & Conditions</a></div>
      <div className="col-4"><a className="footer_text" href="https://www.myimpactmeter.com/end-user-license-agreement/  ">EULA</a></div>
      <div className="col-4"><a className="footer_text" href="https://www.myimpactmeter.com/privacy-policy-2/  ">Privacy Policy</a></div>
 
</div>
    </>
  );
};

export default Forms;
