import React from 'react'
import "./confirm.css";
import tick from '../assets/tick.png'
import logo from '../assets/logo.png'

function    Confirm() {
  return (
   
 <div>
  
  <section className="section-two">
    <div className="container-two">
      <div className="container-two-content content">
      
      
      <br></br>
      <br></br>
      <a href='https://www.myimpactmeter.com/'><img className='logoimg' src={logo} alt="Italian Trulli" /></a>
      
      <img className='tickimg' src={tick} alt="Italian Trulli" />
      <br></br>
      
      

      
<center ><div className='cnfrmtxt'> <h4>Your transaction has been successful.</h4> <br></br>
      
      
      <h2>Thank you for supporting the flood relief efforts.</h2></div></center>
       
      <br></br>
      <center><a href='https://mim.myimpactmeter.com/' className="confirmbtn">Give Again</a></center>
      <br></br>
      <center>
      <b>Powered by <a href='https://www.myimpactmeter.com/' className="text_color" >My Impact Meter</a></b>
      </center>
      
     

      </div>
     
    </div>
  </section>
 
</div>


  )
}

export default Confirm
