import React, { useState  } from "react";
import "./login.css";
import { useEffect } from "react";
import { Postapilogin, saveAuthToken,postforgetsendotp } from "../redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useNavigate  } from "react-router-dom";
function Forgot_password() {
 
    let navigate = useNavigate();

    //staes for storage of data
    const [email, setemail] = useState();
    const [Password, setpaswoord] = useState();
   //get login responce data
    const dispatch = useDispatch();
    let responseData1 = useSelector((state) => state.reducer.sendotp);
   
    let forgetPassword = useSelector((state) => state.reducer.forgetPassword);
  //USE EFFECT TO SEND OTP AND NAVIGATE TO PASSOTP PAGE
    useEffect(()=>{
        console.log(forgetPassword)
      if(forgetPassword){
        if (forgetPassword.success == true) {
            alert("PIN has been sent to your given email address: " +email)
          navigate("/Passotp");
        }else{
         
          alert(forgetPassword.message)
        }
      }
    },[forgetPassword])
    



///BACK BUTTON FUNCTION
    const routeChange = () =>{ 
      let path = `/index.html`; 
      navigate(path);
    }
   
   
     
      
  
    //FORGET PASSWORD BUTTON FUNCTION

    const clickHandler = (e) => {
      e.preventDefault();
      //REGEX FOR MAIL
      if (/^(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i.test(email)){
        const finalData = {
          email: email,
        };
        /////SUBMIT BODY FOR forgot otp 
        
          
         dispatch(postforgetsendotp(finalData))
      }
      else{
        alert("Please enter your valid email address");
      }
    
   
   
     
      
    };
  
  
    return (
      <div className='bg2'>
      <div className='wrap2'>
      <form>
  
      <div className="loginForm">
  
      <h3>Reset Your Password</h3>
      </div>
  
      <div className="form-group">
          <label>Please Enter Your Registered Email Address </label>
          <input onChange={(e) => {
                          setemail(e.target.value);
                        }} type="email" className="form-control" placeholder="Enter your email address"  />
      </div>
  
      
  
      
  
      <button onClick={(e) => {
                      clickHandler(e);
                    }} type="submit" className="btn btn-dark btn-block">Continue</button>

<br>
  
</br>
<button onClick={routeChange} type="submit" className="btn btn btn-block btn_back_home">Back To Home Page</button>
  

  </form>     
  </div>
  </div>
  )
}

export default Forgot_password
